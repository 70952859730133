var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c("h3", [_vm._v("First draggable with header")]),
          _c(
            "draggable",
            {
              staticClass: "list-group",
              attrs: {
                id: "first",
                "data-source": "juju",
                list: _vm.list,
                draggable: ".item",
                clone: _vm.clone,
                group: "a",
                move: _vm.move1,
              },
            },
            [
              _vm._l(_vm.list, function (element) {
                return _c(
                  "div",
                  { key: element.name, staticClass: "list-group-item item" },
                  [_vm._v("\n        " + _vm._s(element.name) + "\n      ")]
                )
              }),
              _c("div", {
                staticClass: "btn-group list-group-item",
                attrs: {
                  slot: "footer",
                  role: "group",
                  "aria-label": "Basic example",
                },
                slot: "footer",
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c("h3", [_vm._v("Second draggable with header")]),
          _c(
            "draggable",
            {
              staticClass: "list-group",
              attrs: { list: _vm.list2, draggable: ".item", group: "a" },
            },
            [
              _vm._l(_vm.list2, function (element) {
                return _c(
                  "div",
                  { key: element.name, staticClass: "list-group-item item" },
                  [_vm._v("\n        " + _vm._s(element.name) + "\n      ")]
                )
              }),
              _c("div", {
                staticClass: "btn-group list-group-item",
                attrs: {
                  slot: "footer",
                  role: "group",
                  "aria-label": "Basic example",
                },
                slot: "footer",
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("rawDisplayer", {
        staticClass: "col-2",
        attrs: { value: _vm.list, title: "List" },
      }),
      _c("rawDisplayer", {
        staticClass: "col-2",
        attrs: { value: _vm.list2, title: "List2" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }